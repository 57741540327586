import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import ReactPaginate from 'react-paginate'
import admindata from '../../components/admindata'
import Loader from 'react-js-loader'
import moment from "moment";

// import loader from '../icons/loder.gif'

const $ = require('jquery')
$.Datatable = require('datatables.net')
export default function BlockUser () {
  const [loading, setLoading] = useState(false)

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API
  const nodeMode = process.env.NODE_ENV
  if (nodeMode === 'development') {
    var baseUrl = beckendLocalApiUrl
  } else {
    baseUrl = beckendLiveApiUrl
  }

  //use for pagination..
  let [limit, setLimit] = useState(10)
  const setpageLimit = event => {
    let key = event.target.value
    setLimit(key)
  }
  const [pageNumber, setPageNumber] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)
  //user for searching..
  const [searchList, setSearchList] = useState(0)
  const [searchType, setSearchType] = useState(0)
  const [findByStatus, setFindByStatus] = useState(0)
  const [findByuserStatus, setFindByuserStatus] = useState('')

  //react paginate..
  const handlePageClick = async data => {
    let currentPage = data.selected + 1
    setPageNumber(currentPage)
    // scroll to the top
    //window.scrollTo(0, 0)
  }

  //   searching handler
  const searchHandler = event => {
    let key = event.target.value
    setSearchList(key)
  }

  //   search by status handler
  const searchByStatus = event => {
    let key = event.target.value
    setFindByStatus(key)
    setFindByuserStatus(key)
  }

  const [user, setUser] = useState(false)
  const getUser = async () => {
    // setLoading(true)
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    // const data = await axios
     axios.get(
        baseUrl +
          `User/all?page=${pageNumber}&_limit=${limit}&_q=0&_stype=0&_status=block&_Userstatus=block`,
        { headers }
      )
      .then(res => {
    // setLoading(false)
        setUser(res.data.admin)
        setNumberOfPages(res.data.totalPages)
        // $('table').dataTable();
      })
      .catch(e => {alert(e)
    // setLoading(false)
      })
  }

  const blockPlayer = async player => {
    const userType = player.user_type == 'Block' ? 'User' : 'Block'
    var text1
    // alert(userType)
    if (userType == 'Block') {
      var { value: text1 } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'Block Reason',
        inputPlaceholder: 'Type your reason here...',
        inputAttributes: {
          'aria-label': 'Type your reason here'
        },
        showCancelButton: true
      })
    } else {
      text1 = 'unblock'
    }
    if (text1) {
      // if (confirmBox === true) {
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      axios
        .post(
          baseUrl + `block/user/${player._id}`,
          { user_type: userType, reason: text1 },
          { headers }
        )
        .then(res => {
          getUser(res.data)
          console.log(res.data)
        })

      // }
    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Enter Reason',
        showConfirmButton: false,
        timer: 1500
      })
    }
  }

  useEffect(() => {
    getUser()
    console.log(admindata, 'admindata')
  }, [
    pageNumber,
    limit,
    searchList,
    searchType,
    findByStatus,
    findByuserStatus
  ])

  return loading ? (
    <>
      <div className='App'>
        <div className='item dashboaed_loader'>
          <Loader
            type='bubble-ping'
            bgColor={'#fff'}
            // title={<img src={loader} alt="Loading..." />}
            color={'#000'}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    user && (
      <div>
        <div className='row mt-5'>
          <div className='col-12 grid-margin'>
            <div className='card text-white'>
              <div className='card-body text-light table_bg'>
                <h4 className='dashboard_heading'>Block User List</h4>

                {/* searching */}
                <div className='row'>
                  <select
                    className='form-control col-sm-3 m-2'
                    id='searchType'
                    name='searchtype'
                    onChange={e => setSearchType(e.target.value)}
                  >
                    <option value='0'>Select Search by</option>
                    <option value='Name'>Name</option>
                    <option value='Phone'>Phone</option>
                    <option value='_id'>User Id</option>
                    <option value='refferal_code'>Refferal Id</option>
                  </select>
                  <input
                    type='text'
                    placeholder='Search...'
                    className='form-control col-sm-4 m-2'
                    onChange={searchHandler}
                  />

                  <h5>Or</h5>

                  <select
                    className='form-control col-sm-1 m-1 bg-dark text-light'
                    id='pagelimit'
                    name='pagelimit'
                    onChange={setpageLimit}
                  >
                    <option value='10'>Set limit</option>
                    <option value='20'>20</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                    <option value='500'>500</option>
                  </select>
                </div>

                <div className='table-responsive'>
                  <table className='table text-light'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> Name</th>
                        <th> Mobile </th>
                        <th> Block by Admin / Agent </th>
                        <th> Block by</th>
                        <th> Reasion</th>
                        <th>Date</th>
                        <th> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {user.map((user, key) => (
                        <tr key={user._id}>
                          <td>{key + 1}</td>
                          {/* <td>
                            <span className='pl-2 text-primary'>
                              {user.Name}
                            </span>
                          </td> */}
                           <td>
                              {user.Name && (
                                <Link
                                  className='btn btn-sm btn-outline-info'
                                  to={`/user/view_user/${user._id}`}
                                >
                                  {user.Name}
                                </Link>
                              )}
                            </td>
                          {admindata?.user?.usertype == 'Admin' ||
                          admindata?.user?.updateLegalData === true ? (
                            <td className='text-success'>{user.Phone}</td>
                          ) : (
                            <td className='text-success'>{`${
                              '********' + user.Phone.toString().slice(-2)
                            }`}</td>
                          )}
                          <td>{user?.action_by?.user_type}</td>
                          <td className='text-success'>{user.action_by?.Name}</td>
                          <td className='text-success'>{user?.lastmsg}</td>
                          <td>
                           
                              <td>
                                  {moment(user.action_by?.updatedAt).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}{" "}
                                </td>
                            
                          </td>
                          <td>
                            <button
                              type='button'
                              className={`btn  mx-1 ${
                                user.user_type == 'Block'
                                  ? 'btn-success'
                                  : 'btn-danger'
                              }`}
                              onClick={() => {
                                blockPlayer(user)
                              }}
                            >
                              {user.user_type == 'Block' ? 'Unblock' : 'Block'}
                            </button>
                           
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className='mt-4'>
                  <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={numberOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
